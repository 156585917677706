<template>
  <header>
    <div class="navbar-fixed">
      <nav>
        <div class="container nav-wrapper">
          <ul id="nav-mobile" class="left">
            <a href="#" data-target="user-menu" class="sidenav-trigger show-on-large waves-effect"><i class="material-icons">account_circle</i></a>
          </ul>

          <img  src="/img/logo.png" alt="Kayak Korner" class="brand-logo center responsive-img" style="max-height: 45px; margin: 5px;">

          <ul id="nav-mobile" class="right">
            <li><a href="#" data-target="general-menu" class="sidenav-trigger show-on-large waves-effect"><i class="material-icons">settings</i></a></li>
          </ul>
        </div>
      </nav>
    </div>

    <ul id="user-menu" class="sidenav">
      <li><a class="subheader">User Menu</a></li>
      <li><router-link :to="{ name: 'login' }" class="waves-effect sidenav-close"><i class="material-icons">account_circle</i>Login</router-link></li>
      <li><router-link :to="{ name: 'home' }" class="waves-effect sidenav-close"><i class="material-icons">home</i>Home</router-link></li>
      <li><router-link :to="{ name: 'about' }" class="waves-effect sidenav-close"><i class="material-icons">info</i>About</router-link></li>
    </ul>
    
    <ul id="general-menu" class="sidenav">
      <li><a class="subheader">General Menu</a></li>
      <li><router-link :to="{ name: 'comodin' }" class="waves-effect sidenav-close">Notificaciones</router-link></li>
    </ul>

  </header>

  <main>
      <router-view v-slot="{ Component }">
        <!-- Transition from router.afterEach((to, from)) -->
        <transition name="slide" v-bind:enter-active-class="$route.meta.enterActiveClass" v-bind:leave-active-class="$route.meta.leaveActiveClass">
          <component :is="Component" />
        </transition>
      </router-view>
  </main>

  <footer class="page-footer">
    <span class="hide">{{ activeRouteName }}</span>
    <ul id="foot-menu" class="tabs primary lighten-2 center-align">
        <li class="tab col s3"><router-link :to="{ name: 'calendar' }"><i class="material-icons">today</i></router-link></li>
        <li class="tab col s3"><router-link :to="{ name: 'games' }"><i class="material-icons">videogame_asset</i></router-link></li>
        <li class="tab col s3"><router-link :to="{ name: 'search' }"><i class="material-icons">search</i></router-link></li>
        <li class="tab col s3"><router-link :to="{ name: 'forum' }"><i class="material-icons">forum</i></router-link></li>
        <li class="tab col s3"><router-link :to="{ name: 'chat' }"><i class="material-icons">send</i></router-link></li>
      </ul>
  </footer>
</template>

<script>
import M from 'materialize-css'

// import { useEjerciciosStore } from '@/stores/ejercicios.js';

export default {
  name: 'App',
  // beforeCreate: async () => {
  //   const store = useEjerciciosStore();
  //   await store.fetchEjercicios()
  // },
  watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = (to.name?(to.name+' - '):'') + 'Kayak Korner';
            }
        },
  },
  data () {
    return {
      main_menu: null,
      pestaña_actual: 0
    }
  },
  mounted () {
    M.AutoInit()
    var el = document.querySelector('#foot-menu');
    this.main_menu = M.Tabs.init(el, {});
    setTimeout(() => {
      this.main_menu.select('/'+this.$route.name);
    }, 100);
    this.pestaña_actual = this.main_menu.index;
    var elems = document.querySelectorAll('#general-menu');
    M.Sidenav.init(elems, {edge: 'right'});
  },
  computed: {
    activeRouteName() {
      return this.$route.name;
    }
  },
  updated () {
      var el = document.querySelector('#foot-menu');
      var page = this.$route.name;
      var find = null;
      var indicator = null;
      for (var i = 0; i < el.children.length; i++) {
        if (el.children[i].className.indexOf('tab') === -1) {
          indicator = el.children[i];
          continue;
        }
        if (el.children[i].children[0].getAttribute('href').replace('#', '').replace('/','') === page) {
          find = [i,page];
        }
      }
      if (find === null && indicator) {
        indicator.style.display = 'none';
        this.pestaña_actual = -1;
      } else if (indicator) {
        if (indicator.style.display === 'none'){
          indicator.style.display = 'block';
          M.Tabs.init(el, { });
          this.pestaña_actual = this.main_menu.index;
        }else if (find[0] != this.pestaña_actual) {
          this.main_menu.select('/'+find[1]);
          this.pestaña_actual = find[0];
        }
      }
  }
}

</script>