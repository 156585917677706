<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col s12">
                    <div class="card horizontal">
                        <div class="card-stacked">
                            <div class="card-content">
                                <span class="card-title">{{ regata.nombre }}</span>
                                <p><b>Fecha:</b> {{ regata.fecha }}</p>
                                <p><b>Lugar:</b> {{ regata.lugar }}</p>
                                <p><b>Organizador:</b> {{ regata.organizador }}</p>
                                <p><b>Liga:</b> {{ regata.liga }}</p>
                                <p><b>Fecha de inscripciones:</b> {{ regata.fecha_inscipciones_inicio }} - {{ regata.fecha_inscipciones_fin }}</p>
                                <br>
                                <a class="btn btn-small waves-effect waves-light" :href="regata.link" target="_blank">Ver en RFEP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <CollectionBoxPruebas :lista="this.pruebas"></CollectionBoxPruebas>
        </div>
    </div>
</template>

<script>
import CollectionBoxPruebas from '@/components/CollectionBoxPruebas.vue';
import API from '@/services/api.js';

export default {
    name: 'RegataView',
    components: {
        CollectionBoxPruebas
    },
    data() {
        return {
            regata: {},
            pruebas: []
        }
    },
    props: {
        id: Number,
        info: Object
    },
    created() {
        if (this.info) {
            this.regata = this.info;
        } else {
            API.regata(this.id).then(response => {
                this.regata = response.data;
            });
        }
        API.pruebas(this.id).then(response => {
            this.pruebas = response.data;
        });
    }
}
</script>