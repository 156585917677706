<template>
        <router-link :to="{ name: 'regata', params: { id: data.id } }" class="collection-item">
              <span class="new badge light-blue" data-badge-caption="">{{ data.liga }}</span>
              <span class="new badge amber darken-1" data-badge-caption="">{{ organizador }}</span>
              <span>{{ data.fecha }}</span>
              <p class="title"><b>{{ data.nombre }}</b></p>
              <span class="valign-wrapper"><i class="material-icons">location_on</i>{{ data.lugar }}</span>
        </router-link>
</template>

<script>
export default {
  name: 'CollectionItemRegata',
  props: {
    data: Object
  },
  computed: {
    organizador: function() {
      // solo los caracteres en mayusculas (las iniciales)
      return this.data.organizador.match(/\b([A-Z])/g).join('').toUpperCase();
    }
  }
}
</script>

<style scoped>
.collection-item {
  color: #000 !important;
}
p.title {
  margin-bottom: 0;
}
</style>