<template>
        <router-link :to="{ name: 'club', params: { id: data.id }, query: { info: JSON.stringify(data) } }" class="collection-item">
          <div>

            <span class="title">{{ data.nombre }}</span>
            
          </div>
        </router-link>
</template>

<script>
export default {
  name: 'CollectionItemClub',
  props: {
    data: Object
  }
}
</script>

<style scoped>
.collection-item {
  color: #000 !important;
  min-height: 0 !important;
}
span.badge {
  float: none !important;
  margin-left: 0;
  padding: .2rem .5rem;
}
</style>