<template>
  <div>
    <div class="container">
      <div class="row">
          <div v-if="data" class="col s12 m6">
              <h4>{{ data.nombre }}</h4>
              <p><b>Dirección: </b>{{ data.direccion }}</p>
              <p><b>Web: </b><a :href="web_link" target="_blank">{{ data.web }}</a></p>
              <p><b>Email: </b>{{ data.email }}</p>
              <p><b>Teléfono: </b>{{ data.telefono }}</p>
              <a :href="data.link[0]" target="_blank"><button class="btn waves-effect waves-light">Ver en RFEP</button></a>
          </div>
      </div>

      <div class="row">
          <div class="col s12">
            <CollectionBoxPalistas v-if="this.palistas.length" :lista="this.palistas"></CollectionBoxPalistas>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionBoxPalistas from '@/components/CollectionBoxPalistas.vue';
import API from '@/services/api.js';

export default {
  name: 'ClubView',
  components: {
    CollectionBoxPalistas
  },
  data() {
    return {
      data: {
        web: '',
        link: [undefined]
      },
      palistas: []
    }
  },
  props: {
    id: Number,
    info: Object
  },
  computed: {
    web_link() {
      return this.data.web?.startsWith('http') ? this.data.web : 'http://' + this.data.web;
    }
  },
  created() {
    if (this.info) {
      this.data = this.info;
    } else {
      API.club(this.id).then(response => {
        this.data = response.data;
      });
    }
    API.palistas_del_club(this.id).then(response => {
      this.palistas = response.data;
    });
  }
}
</script>