<template>
    <ul class="collection with-header">
        <li class="collection-header">
          <span class="new badge amber darken-1" :data-badge-caption="palistas.length==1?'palista':'palistas'">{{ palistas.length }}</span>
          <h5>Palistas</h5>
          <div class="input-field">
            <input v-if="habilitar_filtrado" v-model="filtrado" type="text" placeholder="Filtrar">
          </div>
        </li>
        <CollectionItemPalista v-for="item in palistas" :key="item.id" :data="item"></CollectionItemPalista>
    </ul>
</template>

<script>
import CollectionItemPalista from '@/components/CollectionItemPalista.vue';

export default {
  name: 'CollectionBoxPalistas',
  components: {
    CollectionItemPalista
  },
  data() {
    return {
      filtrado: '',
    }
  },
  props: {
    lista: Array,
    habilitar_filtrado: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    palistas() {
      return this.lista.filter((item) => {
        // Filtrar por nombre, apellido, club o categoria
        return item.nombre.toLowerCase().includes(this.filtrado.toLowerCase()) 
            || item.apellido.toLowerCase().includes(this.filtrado.toLowerCase()) 
            || (item.licencia?
                item.licencia.club.nombre.toLowerCase().includes(this.filtrado.toLowerCase()) 
                || item.licencia.categoria.toLowerCase().includes(this.filtrado.toLowerCase())
                :false);
      });
    },
  }
}
</script>