<template>
    <router-link :to="{ name: 'prueba', params: { id: data.id }, query: { info: JSON.stringify(data) } }" class="collection-item">
      <div>
        <p class="title"><b>{{ data.nombre }}</b></p>
        <span>{{data.horario }}</span>
      </div>
    </router-link>
</template>

<script>
export default {
    name: 'CollectionItemPrueba',
    props: {
        data: Object
    }
}
</script>

<style scoped>
.collection-item {
  color: #000 !important;
}
p.title {
  margin: 0;
}
span {
  display: block;
  text-align: right;
}
</style>