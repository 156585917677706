import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CalendarView from '../views/CalendarView.vue'
import GamesView from '../views/GamesView.vue'
import SearchView from '../views/SearchView.vue'
import ForumView from '../views/ForumView.vue'
import ChatView from '../views/ChatView.vue'
import PalistaView from '../views/PalistaView.vue'
import ClubView from '../views/ClubView.vue'
import RegataView from '../views/RegataView.vue'
import PruebaView from '@/views/PruebaView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/search'
  },
  {
    path: '/comodin',
    name: 'comodin',
    component: HomeView
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView
  },
  {
    path: '/games',
    name: 'games',
    component: GamesView
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView
  },
  {
    path: '/forum',
    name: 'forum',
    component: ForumView
  },
  {
    path: '/chat',
    name: 'chat',
    component: ChatView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    'path': '/login',
    'name': 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/palista/:id',
    name: 'palista',
    component: PalistaView,
    props: route => ({
      id: parseInt(route.params.id),
      info: route.query.info?JSON.parse(route.query.info):null
    })
  },
  {
    path: '/club/:id',
    name: 'club',
    component: ClubView,
    props: route => ({
      id: parseInt(route.params.id),
      info: route.query.info?JSON.parse(route.query.info):null
    })
  },
  {
    path: '/regata/:id',
    name: 'regata',
    component: RegataView,
    props: route => ({
      id: parseInt(route.params.id),
      info: route.query.info?JSON.parse(route.query.info):null
    })
  },
  {
    path: '/prueba/:id',
    name: 'prueba',
    component: PruebaView,
    props: route => ({
      id: parseInt(route.params.id),
      info: route.query.info?JSON.parse(route.query.info):null
    })
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to, from) => {
  const orden = ['calendar', 'games', 'search', 'forum', 'chat'];
  const to_position = orden.indexOf(to.name);
  if (to_position == -1) {
    // to other
    to.meta.enterActiveClass = 'animate__animated animate__slideInRight'
    to.meta.leaveActiveClass = 'animate__animated animate__fadeOut'
  }else{
    const from_position = orden.indexOf(from.name);
    if (from_position == to_position) {
      return;
    }
    if (from_position == -1) {
      // from other to main
      to.meta.enterActiveClass = 'animate__animated animate__fadeIn'
      to.meta.leaveActiveClass = 'animate__animated animate__slideOutRight'
    }else{
      // from main to main
      to.meta.enterActiveClass = 'animate__animated ' + (to_position > from_position ? 'animate__slideInRight' : 'animate__slideInLeft')
      to.meta.leaveActiveClass = 'animate__animated ' + (to_position > from_position ? 'animate__slideOutLeft' : 'animate__slideOutRight')
    }
  }
})

export default router
