<template>
  <div>
    <div class="container">
      <div class="row">
          <div v-if="data">
              <h4>{{ nombreCompleto }}</h4>
              <div>
                  <img v-if="data.photo.length > 0" :src="data.photo[0]" alt="Imagen de palista" class="materialboxed">
                  <i v-else class="material-icons large circle">person</i>
              </div>
                <p v-if="data.licencia != undefined"><b>Club: </b><RouterLink :to="{ name: 'club', params: { id: data.licencia.club.id }}" class="">{{ data.licencia.club.nombre }}</RouterLink></p>
                <p>
                    <span v-if="data.licencia != undefined" class="new badge light-blue" data-badge-caption="">{{ data.licencia.categoria }}</span>
                    <b>Edad: </b>{{ edad }}
                </p>
              <p><b>Especialidad: </b>{{ data.especialidad }}</p>
              <a :href="data.link[0]" target="_blank"><button class="btn waves-effect waves-light">Ver en RFEP</button></a>
          </div>
      </div>

      <div class="row">
        <hr>
        <ResultadoPalista v-for="resultado in resultados" :key="resultado.id" :regata="resultado.regata" :prueba="resultado.prueba" :resultado="resultado.resultado"></ResultadoPalista>
      </div>

    </div>
  </div>
</template>

<script>
import ResultadoPalista from '@/components/ResultadoPalista.vue';
import API from '@/services/api.js';
import M from 'materialize-css';

export default {
  name: 'PalistaView',
  components: {
    ResultadoPalista
  },
  data() {
    return {
      data: null,
      resultados: []
    }
  },
  props: {
    id: Number,
    info: Object
  },
  computed: {
    nombreCompleto() {
      return this.data.nombre.toUpperCase() + ' ' + this.data.apellido.toUpperCase();
    },
    edad () {
      return new Date().getFullYear() - this.data.año_nacimiento;
    }
  },
  created() {
    if (this.info) {
      this.data = this.info;
    } else {
      API.palista(this.id).then(response => {
        this.data = response.data;
      });
    }
    API.palista_resultados(this.id).then(response => {
      this.resultados = response.data;
    });
  },
  watch: {
    id() {
      API.palista(this.id).then(response => {
        this.data = response.data;
      });
      API.palista_resultados(this.id).then(response => {
        this.resultados = response.data;
      });
    }
  },
  mounted() {
    var elems = document.querySelectorAll('.materialboxed');
    M.Materialbox.init(elems, {});
  }
}
</script>

<style scoped>
  img {
    height: 6rem;
  }
</style>