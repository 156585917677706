<template>
    <tr>
        <td>
            <b>{{ puesto }}</b>
               <br>
            <span class="badge">({{ dorsal }})</span>
        </td>
        <td>
            <div v-if="integrantes.length <= 2">
                <div v-for="(integrante, index) in integrantes" :key="index">
                    <router-link :to="{ name: 'palista', params: { id: integrante.id } }">
                        {{ integrante.nombre_completo }}
                    </router-link>
                </div>
                <span class="badge">
                    <router-link :to="{ name: 'club', params: { id: this.club_id } }">
                        {{ this.club }}
                    </router-link>
                </span>
            </div>
            <div v-else>
                <router-link :to="{ name: 'club', params: { id: this.club_id } }">
                    {{ this.club }}
                </router-link>
                <br>
                <div v-if="integrantes.length <=5">
                    <span class="badge" v-for="(integrante, index) in integrantes" :key="index">
                        <router-link :to="{ name: 'palista', params: { id: integrante.id } }">
                            {{ integrante.nombre_completo }}
                        </router-link>
                    </span>
                </div>
                <div v-else>
                    <span class="badge" v-for="(integrante, index) in integrantes" :key="index">
                        <router-link :to="{ name: 'palista', params: { id: integrante.id } }">
                            {{ integrante.nombre }}
                        </router-link>
                    </span>
                </div>
            </div>
        </td>
        <td>
            {{ tiempo }}
            <br>
            {{ estado }}
        </td>
    </tr>
</template>

<script>
export default {
    name: 'CollectionItemResultado',
    props: {
        data: Object,
        num_max_milisegundos: Number
    },
    computed: {
        integrantes() {
            return this.data.integrantes.map(integrante => ({
                nombre: integrante.palista.nombre.toLowerCase(),
                nombre_completo: integrante.palista.nombre.toLowerCase() + ' ' + integrante.palista.apellido.toLowerCase(),
                id: integrante.palista.id
            }));
        },
        puesto() {
            return this.data.posicion;
        },
        dorsal() {
            return this.data.dorsal || this.data.calle;
        },
        club() {
            return this.data.integrantes[0].licencia.club.nombre;
        },
        club_id() {
            return this.data.integrantes[0].licencia.club.id;
        },
        estado() {
            // remove this "Estado: " from the string
            return this.data.anotaciones.replace('Estado: ', '');
        },
        tiempo() {
            // separar horas, minutos, segundos y milisegundos 
            const tiempo = this.data.tiempo.replace('.', ':').split(':');
            if (tiempo.length == 1) {
                return tiempo[0];
            }
            const horas = tiempo[0];
            const minutos = tiempo[1];
            const segundos = tiempo[2];
            let milisegundos = tiempo[3];
            // quitar 0s a la derecha
            milisegundos = milisegundos.replace(/0+$/, '');
            // agregar 0s a la derecha
            milisegundos = milisegundos.padEnd(this.num_max_milisegundos, '0');
            if (milisegundos) {
                milisegundos = '.' + milisegundos;
            }
            // si no hay horas, no mostrarlas y si no hay ni minutos, mostrar solo segundos
            if (horas === '00') {
                if (minutos === '00') {
                    return segundos + milisegundos;
                }
                return minutos + ':' + segundos + milisegundos;
            }else {
                return horas + ':' + minutos + ':' + segundos + milisegundos;
            }
        }
    }
}

</script>

<style scoped>
a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}
th, td {
    text-transform: capitalize !important;
}
</style>