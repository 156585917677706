<template>
    <ul class="collection with-header">
        <li class="collection-header">
          <span class="new badge amber darken-1" :data-badge-caption="lista.length==1?'club':'clubes'">{{ lista.length }}</span>
          <h5>Clubes</h5>
        </li>
        <CollectionItemClub v-for="item in lista" :key="item.id" :data="item"></CollectionItemClub>
    </ul>
</template>

<script>
import CollectionItemClub from '@/components/CollectionItemClub.vue';

export default {
  name: 'CollectionBoxClubs',
  components: {
    CollectionItemClub
  },
  props: {
    lista: Array
  }
}
</script>