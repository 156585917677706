<template>
  <div>
    <div class="container">
      <div class="row">
        <h3>Calendario</h3>
        <form class="form-inline" @submit.prevent="getRegatas">
          <input type="number" name="year" id="year" v-model="year">
          <button class="btn">Buscar</button>
        </form>
      </div>
      <CollectionBoxRegatas :lista="regatas"></CollectionBoxRegatas>
    </div>
  </div>
</template>

<script>
import CollectionBoxRegatas from '@/components/CollectionBoxRegatas.vue';
import API from '@/services/api.js';

export default {
  name: 'CalendarView',
  components: {
    CollectionBoxRegatas
  },
  data() {
    return {
      year: 2023,
      regatas: []
    }
  },
  methods: {
    getRegatas() {
      API.regatas(this.year).then((response) => {
        this.regatas = response.data;
      });
    }
  }
}
</script>