<template>
    <div>
        <router-link :to="{ name: 'regata', params: { id: regata.id }, query: { info: JSON.stringify(regata) } }">
            <h6>{{ regata.nombre }}</h6>
        </router-link>
        <router-link :to="{ name: 'prueba', params: { id: prueba.id } }">
            <span class="badge">{{ prueba.nombre }}</span>
        </router-link>
      <table class="striped centered">
        <tbody>
          <CollectionItemResultado :data="resultado" :num_max_milisegundos=num_max_milisegundos></CollectionItemResultado>
        </tbody>
      </table>
      <hr>
    </div>
    
  </template>
  
  <script>
  import CollectionItemResultado from '@/components/CollectionItemResultado.vue';
  
  export default {
    name: 'ResultadoPalista',
    components: {
      CollectionItemResultado
    },
    props: {
      regata: Object,
      prueba: Object,
      resultado: Object
    },
    computed: {
      num_max_milisegundos() {
        let milisegundos = this.resultado.tiempo.split('.');
        if (milisegundos.length > 1) {
          return milisegundos[1].replace(/0+$/, '').length;
        } else {
          return 0;
        }
      }
    }
  }
  </script>
  
  <style scoped>
  a {
    color: #000;
    text-decoration: underline;
  }
  h6,span.badge {
    margin: 0;
    display: block;
    float: none;
    text-align: center;
  }
  </style>