<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col s12">
                    <div class="card horizontal">
                        <div class="card-stacked">
                            <div class="card-content">
                                <b class="card-title">{{ prueba.nombre }}</b>
                                <b>{{ regata.nombre }}</b>
                                <p><b>Lugar:</b> {{ regata.lugar }}</p>
                                <p><b>Organizador:</b> {{ regata.organizador }}</p>
                                <p><b>Liga:</b> {{ regata.liga }}</p>
                                <p><b>Horario:</b> {{ prueba.horario }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <CollectionBoxResultados :lista="resultados"></CollectionBoxResultados>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CollectionBoxResultados from '@/components/CollectionBoxResultados.vue';
import API from '@/services/api.js';

export default {
    name: 'PruebaView',
    components: {
        CollectionBoxResultados
    },
    data() {
        return {
            resultados: [],
            prueba: {},
            regata: {}
        }
    },
    props: {
        id: Number,
        info: Object
    },
    created() {
        API.prueba(this.id).then(response => {
            this.prueba = response.data;
            API.regata(this.prueba.regata).then(response => {
                this.regata = response.data;
            });
        });
        API.resultados(this.id).then(response => {
            this.resultados = response.data;
        });
    }
}
</script>