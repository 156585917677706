import axios from 'axios';

const apiClient = axios.create({
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

export default {
    search(query='') {
        return apiClient.get('/api/search', {
            params: {
                'q': query
            }
        });
    },
    club(id) {
        return apiClient.get('/api/club/' + id);
    },
    palista(id) {
        return apiClient.get('/api/palista/' + id);
    },
    palista_resultados(id) {
        return apiClient.get('/api/palista/' + id + '/resultados');
    },
    palistas_del_club(id) {
        return apiClient.get('/api/club/' + id + '/palistas');
    },
    regatas(año) {
        return apiClient.get('/api/regatas', {
            params: {
                'year': año
            }
        });
    },
    regata(id) {
        return apiClient.get('/api/regata/' + id);
    },
    pruebas(id) {
        return apiClient.get('/api/regata/' + id + '/pruebas');
    },
    prueba(id) {
        return apiClient.get('/api/prueba/' + id);
    },
    resultados(id) {
        return apiClient.get('/api/prueba/' + id + '/resultados');
    },
};