<template>
    <ul class="collection with-header">
        <li class="collection-header">
          <h5>Regatas</h5>
        </li>
        <CollectionItemRegata v-for="item in lista" :key="item.id" :data="item"></CollectionItemRegata>
    </ul>
</template>

<script>
import CollectionItemRegata from '@/components/CollectionItemRegata.vue';

export default {
  name: 'CollectionBoxRegatas',
  components: {
    CollectionItemRegata
  },
  props: {
    lista: Array
  }
}
</script>