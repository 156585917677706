<template>
    <ul class="collection with-header">
        <li class="collection-header">
          <h5>Pruebas</h5>
          <div class="input-field">
            <input v-if="habilitar_filtrado" v-model="filtrado" type="text" placeholder="Filtrar">
          </div>
        </li>
        <CollectionItemPrueba v-for="item in pruebas" :key="item.id" :data="item"></CollectionItemPrueba>
    </ul>
</template>

<script>
import CollectionItemPrueba from '@/components/CollectionItemPrueba.vue';

export default {
  name: 'CollectionBoxPrueba',
  components: {
    CollectionItemPrueba
  },
  data() {
    return {
      filtrado: '',
    }
  },
  props: {
    lista: Array,
    habilitar_filtrado: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    pruebas() {
      return this.lista.filter((item) => {
        return item.nombre.toLowerCase().includes(this.filtrado.toLowerCase());
      });
    },
  }
}
</script>