<template>
        <router-link :to="{ name: 'palista', params: { id: data.id }, query: { info: JSON.stringify(data) } }" class="collection-item avatar">
          <div>
              <img v-if="data['photo'].length!=0" :src="data['photo'][0]" alt='' class="circle">
              <i v-else class="material-icons circle">person</i>

              <span class="title"><b>{{ nombre }}</b></span>
              <div v-if="data.licencia != undefined">
                <p>{{ data.licencia.club.nombre }}</p>
                <span class="new badge light-blue" data-badge-caption="">{{ data.licencia.categoria }}</span>
              </div>
              <p v-else>&nbsp;</p>
            
          </div>
        </router-link>
</template>

<script>
export default {
  name: 'CollectionItemPalista',
  props: {
    data: Object,
  },
  computed: {
    nombre() {
      var nombre =  (this.data.nombre?this.data.nombre:'') + ' ' + (this.data.apellido?this.data.apellido:'');
      nombre = nombre.trim();
      // en nombre cada primera letra de cada palabra en mayúscula y el resto en minusculas
      nombre = nombre.toLowerCase().replace(/(^|\s)[\wáéíóúüñ]/g, l => l.toUpperCase())
      if (nombre == '') {
        return '&nbsp;';
      }
      return nombre;
    }
  }
}
</script>

<style scoped>
.collection-item {
  color: #000 !important;
  min-height: 0 !important;
}
span.badge {
  float: none !important;
  margin-left: 0;
  padding: .2rem .5rem;
}
</style>