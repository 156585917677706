<template>
  <div>
    <h5>Resultados</h5>
    <div class="input-field">
      <input v-if="habilitar_filtrado" v-model="filtrado" type="text" placeholder="Filtrar">
    </div>
    
    <table class="striped centered">
      <thead>
        <tr>
          <th>#</th>
          <th>Palistas</th>
          <th>Tiempo</th>
        </tr>
      </thead>
      <tbody>
        <CollectionItemResultado v-for="item in resultados" :key="item.id" :data="item" :num_max_milisegundos=num_max_milisegundos></CollectionItemResultado>
      </tbody>
    </table>
  </div>
  
</template>

<script>
import CollectionItemResultado from '@/components/CollectionItemResultado.vue';

export default {
  name: 'CollectionBoxResultados',
  components: {
    CollectionItemResultado
  },
  data() {
    return {
      filtrado: '',
    }
  },
  props: {
    lista: Array,
    habilitar_filtrado: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    resultados() {
      return this.lista.filter((item) => {
        const club = item.integrantes[0].licencia.club.nombre;
        const nombres = item.integrantes.map(integrante => `${integrante.palista.nombre} ${integrante.palista.apellido}`).join(' ');
        const dorsal = item.dorsal || item.calle;
        return nombres.toLowerCase().includes(this.filtrado.toLowerCase()) 
          || club.toLowerCase().includes(this.filtrado.toLowerCase())
          || dorsal.toString().includes(this.filtrado);
      });
    },
    num_max_milisegundos() {
      return this.lista.reduce((max, item) => {
        return Math.max(max, (item.tiempo.split('.').length == 2)?item.tiempo.split('.')[1].replace(/0+$/, '').length:0);
      }, 0);
    }
  }
}
</script>

<style scoped>
</style>