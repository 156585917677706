<template>
  <div>
    <div class="container">
      <div class="row">
        <h3>Busqueda</h3>
        <input type="text" v-model="search" @keyup.enter="searchFor" placeholder="Buscar...">
      </div>

      <CollectionBoxRegatas v-if="this.results.regatas.length" :lista="this.results.regatas"></CollectionBoxRegatas>

      <CollectionBoxPalistas v-if="this.results.palistas.length" :lista="this.results.palistas" :habilitar_filtrado="false"></CollectionBoxPalistas>

      <CollectionBoxClubs v-if="this.results.clubs.length" :lista="this.results.clubs"></CollectionBoxClubs>
    </div>
  </div>
</template>

<script>
import API from '@/services/api.js';
import CollectionBoxPalistas from '@/components/CollectionBoxPalistas.vue';
import CollectionBoxClubs from '@/components/CollectionBoxClubs.vue';
import CollectionBoxRegatas from '@/components/CollectionBoxRegatas.vue';

export default {
  name: 'SearchView',
  components: {
    CollectionBoxPalistas,
    CollectionBoxClubs,
    CollectionBoxRegatas
  },
  data() {
    return {
      search: '',
      results: {
        "clubs": [],
        "palistas": [],
        "regatas": []
      }
    }
  },
  methods: {
    async searchFor() {
      const response = await API.search(this.search);
      this.results = response.data;
    }
  }
}
</script>
